// Global packages and components
import { useState, useEffect } from 'react'
import { FormInput, Button, InlineLink } from '~/components'
import { Form, useFetcher } from '@remix-run/react'
import { action } from '~/routes/api.login'

// Main export
const FormLogin = ({ redirect }: { redirect?: string }) => {
  // Hooks
  const fetcher = useFetcher<typeof action>()

  // Form field states
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  // Other form states
  const [loading, setLoading] = useState<boolean>(false)

  // Submit to API route
  const handleSubmit = () => {
    fetcher.submit(
      { email, password, redirect: redirect ?? null },
      {
        method: 'post',
        encType: 'application/json',
        action: '/api/login',
      }
    )
  }

  // On submission state
  useEffect(() => {
    if (fetcher?.state == 'loading') {
      setLoading(true)
    }

    if (fetcher?.state == 'idle') {
      setLoading(false)
    }
  }, [fetcher])

  return (
    <Form
      method="post"
      className="mx-auto flex flex-col gap-4"
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <FormInput
        type="email"
        name="email"
        label="Email Address"
        placeholder="Enter your email address"
        value={email}
        onChange={e => setEmail(e)}
      />
      <FormInput
        name="password"
        label="Password"
        type="password"
        placeholder="Enter your password"
        value={password}
        onChange={e => setPassword(e)}
      />
      <div className="mt-6">
        <Button
          title={!loading ? 'Log in' : 'Logging in..'}
          type="submit"
          disabled={!email || !password || loading}
          fullWidth
        />
      </div>
      <div className="mt-4 text-center">
        <InlineLink to="/request-reset-password">Forgotten Password</InlineLink>
      </div>
    </Form>
  )
}

export default FormLogin
